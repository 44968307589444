import { useLazyQuery } from '@apollo/client';
import Link from 'next/link';
import PropTypes from 'prop-types';
import {
  useContext,
  useState,
  useEffect,
  useCallback
} from 'react';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { JobFormCtaText, JobFormSection } from 'lib/oneflare-job-form/utils/constants';
import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { getDomain, getSiteUrl } from 'lib/utils/Environment';
import { AUTOCOMPLETE_LOCATION } from 'queries/shared/autocompleteLocation';
import { CTA_TYPES } from 'shared/components/PageHeader/Assets';
import { CategoryObjectType } from 'shared/types';

import DSPCta from './components/DspCta';
import PostJobAutoCompleteCta from './components/PostJobAutoCompleteCta';
import PostJobCta from './components/PostJobCta';
import {
  ContainerStyled,
  CobrandingStyled,
  HeaderStickyStyled,
  WeddingLogoStyled,
  LogoWrapperStyled
} from './styled/HeaderStickyStyled';

const propTypes = {
  autocompleteTitle: PropTypes.string,
  category: CategoryObjectType,
  ctaKind: PropTypes.oneOf(Object.values(CTA_TYPES)),
  directLeadsNumber: PropTypes.string,
  handleCallClick: PropTypes.func,
  postcode: PropTypes.string,
  showStickyHeader: PropTypes.bool.isRequired,
  useGetFreeQuoteText: PropTypes.bool,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const SITE_URL = getSiteUrl();
const DOMAIN = getDomain();

const LogoLink = () => {
  switch (DOMAIN) {
    case 'wedding':
      return (
        <Link href="/" aria-label="wedding.com.au logo">
          <WeddingLogoStyled />
        </Link>
      );
    default:
      return (
        <Link href={SITE_URL}>
          <CobrandingStyled />
        </Link>
      );
  }
};

const HeaderSticky = ({
  showStickyHeader,
  autocompleteTitle,
  directLeadsNumber,
  handleCallClick = () => { },
  ctaKind = CTA_TYPES.POST_JOB,
  category = { id: null},
  useGetFreeQuoteText = false,
  postcode,
  jobFormInitiatedPageName
}) => {
  const [location, setlocation] = useState({ value: '', valid: false, id: null });
  const [locationOptions, setLocationOptions] = useState([]);
  const [getLocations] = useLazyQuery(AUTOCOMPLETE_LOCATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { autocompleteLocation } = data;
      if (autocompleteLocation) {
        const formatted = autocompleteLocation.map((value) => ({
          ...value,
          name: value.value,
          value: value.value
        }));
        setLocationOptions(formatted);
      }
    }, 
    onError: (error) => {
      DataDogRumAgent.addRumError(error, 'HeaderSticky.jsx | AUTOCOMPLETE_LOCATION query');
    }
  });

  const { controller } = useContext(JobFormControllerContext);
  const openJobForm = useCallback(async () => {
    const { id: categoryId } = category;
    const { id: locationId } = location;
    await controller.open({ categoryId, locationId });
  }, [category, controller, location]);

  useEffect(() => {
    const { id: locationId, valid: validLocation } = location;
    if (locationId || validLocation) {
      openJobForm();
    }
  }, [location, openJobForm]);

  const handleChange = (({ target: { value } }) => {
    if (value && value.trim().length > 0) {
      getLocations({ variables: { term: value }});
    }
    setlocation({ valid: false, id: null, value });
  });

  const handleSelect = (option) => {
    const location = locationOptions.find((item) => item.value === option.value);
    setlocation({ value: option.value, valid: Boolean(location), id: location.id });
  };

  const handleJobFormLaunch = useCallback(async () => {
    const { value, valid, id: locationId } = location;
    const { id: categoryId } = category;
    await controller?.open({
      categoryId,
      locationId,
      locationName: value && valid
        ? value.split(', ')[0]
        : !valid
          ? value
          : postcode,
      page: jobFormInitiatedPageName,
      section: JobFormSection.NavigationBar,
      ctaText: useGetFreeQuoteText ? JobFormCtaText.GetFreeQuotes : JobFormCtaText.PostAJob,
      categoryName: category.name,
      postcode: postcode
    });
  }, [category, controller, location, postcode]);

  const ctaOptions = {
    [CTA_TYPES.POST_JOB]: {
      CTAComponent: PostJobCta,
      ctaProps: {
        onClick: handleJobFormLaunch,
        useGetFreeQuoteText
      }
    },
    [CTA_TYPES.POST_JOB_AUTOCOMPLETE]: {
      CTAComponent: PostJobAutoCompleteCta,
      ctaProps: {
        onClick: handleJobFormLaunch,
        onChange: handleChange,
        onSelect: handleSelect,
        items: locationOptions.map(({ name, value }) => ({ name, value })),
        autocompleteTitle,
        value: location.value,
        useGetFreeQuoteText
      }
    },
    [CTA_TYPES.DSP]: {
      CTAComponent: DSPCta,
      ctaProps: {
        handleCallClick,
        directLeadsNumber
      }
    }
  };

  const { CTAComponent, ctaProps } = ctaOptions[ctaKind];

  return (
    <HeaderStickyStyled $showStickyHeader={showStickyHeader}>
      <ContainerStyled>
        <LogoWrapperStyled>
          <LogoLink />
        </LogoWrapperStyled>
        <CTAComponent
          {...ctaProps}
        />
      </ContainerStyled>
    </HeaderStickyStyled>
  );
};

HeaderSticky.propTypes = propTypes;

export default HeaderSticky;
