import { JobFormQuestion } from 'types/oneflare.com.au/jobForm';

// #region - General question's IDs
export const CATEGORY_QUESTION_ID = 'category-question';
export const CONTACT_DETAILS_ID = 'contact-details';
export const DESCRIPTION_QUESTION_ID = 'description-question';
export const EMAIL_QUESTION_ID = 'email-question';
export const LOCATION_QUESTION_ID = '2740';
export const MOBILE_VERIFICATION_ID = 'mobile-verification';
export const GENERAL_QUESTION_IDS = [
  CATEGORY_QUESTION_ID,
  CONTACT_DETAILS_ID,
  DESCRIPTION_QUESTION_ID,
  EMAIL_QUESTION_ID,
  LOCATION_QUESTION_ID,
  MOBILE_VERIFICATION_ID
];
// #endregion - General question's IDs

export const DEFAULT_NUMBER_OF_OPTIONS_SHOWN = 5;

export const OTHER_OPTION_LABEL = 'Other (Please specify)';
export const SPECIFIC_DATE_OPTION_LABEL = 'Specific date';

export const DESCRIPTION_QUESTION_TEXT = 'Any further details?';

// #region - Datepicker input type
export const SINGLE_DATE_OPTION = 'Exact date';
export const DATE_RANGE_OPTION = 'I\'m flexible';
export const DATEPICKER_SWITCH_OPTIONS = [SINGLE_DATE_OPTION, DATE_RANGE_OPTION];
// #endregion - Datepicker input type

export const CONTACT_QUESTIONS = [
  {
    id: 'name',
    question: 'What\'s your name?',
    placeholder: 'e.g. Jon Snow',
    maxLength: 30,
    errorMessage: 'Please enter your name'
  },
  {
    id: 'phone',
    question: 'What\'s the best mobile number?',
    placeholder: 'e.g. 0491 570 110',
    maxLength: 10,
    errorMessage: 'Please enter a valid mobile'
  }
];

export const SCHEDULER_ANSWERS = [
  'As soon as possible',
  'Any time that works',
  'Specific date'
];

// #region - Tracking
export const FORM_VERSION = '5';
export const FORM_TYPE = 'job_request';
// #endregion - Tracking

// #region - Datadog Context
export const JOB_FORM_CATEGORY_ID = 'job_form.category_id';
export const JOB_FORM_OPEN = 'job_form.open';
// #endregion - Datadog Context

// Categories that are marked as v2 and have been migrated to new database on BE
export const V2_CATEGORIES = [
  '108', // car-detailing
  '371', // car-window-tinting
  '111', // mechanic
  '283', // panel-beaters
  '373', // prepurchase-car-inspection
  '164', // towing
  '289', // windscreen-repair
  '198', // wedding-florist
  '273', // massage
  '267', // naturopath
  '205', // office-admin
  '277', // physiotherapy
  '199', // real-estate-agent
  '106', // seo
  '328' // welding
];

type JobFormInteractionEvents = {
  form_name: string; 
  categoryId: number;
  locationId: number;
  questionId: number;
  serviceTypeId: Array<number>;
  formStep: number | string;
  jobId: number;
  label?: string;
  answerValue?: string;
}

export const JOB_FORM_INTERACTION_EVENTS = ({
  form_name, categoryId, locationId, questionId, serviceTypeId, formStep, jobId, label, answerValue
}: JobFormInteractionEvents) => ({
  jobFormLanding: {
    event: 'landing_page_pageview',
    form_step: '01',
    label: '',
    action: 'landing',
    form_name,
    form_version: FORM_VERSION,
    form_type: FORM_TYPE,
    category_id: categoryId || 0,
    location_id: locationId || 0,
    value: ''
  },
  jobFormStart: {
    event: 'form_start',
    form_step: '02',
    question_id: questionId,
    label: '',
    action: 'landing',
    form_name,
    form_version: FORM_VERSION,
    form_type: FORM_TYPE,
    category_id: categoryId,
    location_id: locationId || 0,
    value: questionId || 0
  },
  jobFormAnswer: {
    event: 'form_answer',
    form_step: formStep,
    question_id: questionId,
    label: label ?? '',
    action: 'landing',
    form_name,
    form_version: FORM_VERSION,
    form_type: FORM_TYPE,
    category_id: categoryId,
    location_id: locationId || 0,
    service_type_id: serviceTypeId ?? [],
    value: questionId || 0,
    answer_value: answerValue ?? ''
  },
  jobFormContinue: {
    event: 'form_continue',
    form_step: formStep,
    question_id: questionId,
    label: '',
    action: 'landing',
    form_name,
    form_version: FORM_VERSION,
    form_type: FORM_TYPE,
    category_id: categoryId,
    location_id: locationId || 0,
    service_type_id: serviceTypeId ?? [],
    value: questionId || 0
  },
  jobFormComplete: {
    event: 'form_complete',
    form_step: formStep,
    label: '',
    action: 'landing',
    form_name,
    form_version: FORM_VERSION,
    form_type: FORM_TYPE,
    category_id: categoryId,
    location_id: locationId || 0,
    service_type_id: serviceTypeId ?? [],
    job_id: jobId,
    value: questionId
  }
});

export const ATTACHMENTS_TRACKING_EVENTS = (questionId: number) => ({
  addAttachment: {
    event: 'interaction',
    category: 'attachments',
    action: 'add',
    value: questionId
  },
  removeAttachment: {
    event: 'interaction',
    category: 'attachments',
    action: 'remove',
    value: questionId
  }
});

export const DATE_PICKER_TRACKING_EVENTS = {
  openCalendar: {
    event: 'interaction',
    category: 'calendar_modal',
    action: 'open'
  },
  closeCalendar: {
    event: 'interaction',
    category: 'calendar_modal',
    action: 'close'
  },
  autoSelectDate: {
    event: 'interaction',
    category: 'calendar_modal',
    action: 'auto_select_date'
  },
  clickSelectDate: {
    event: 'interaction',
    category: 'calendar_modal',
    action: 'click_select_date'
  }
};

export const SCHEDULER_TRACKING_EVENTS = {
  selectApproximateDate: {
    event: 'interaction',
    category: 'calendar_modal',
    action: 'select_approximate_date'
  },
  deselectApproximateDate: {
    event: 'interaction',
    category: 'calendar_modal',
    action: 'deselect_approximate_date'
  }
};

export const MOBILE_VERIFICATION_TRACKING_EVENTS = {
  clickResendCode: {
    event: 'interaction',
    category: 'send_again',
    action: 'click_text',
    label: 'mobile_verification_code'
  },
  resendCodeSuccess: {
    event: 'interaction',
    category: 'repeat_customer_flow',
    action: 'click_text',
    label: 'resend_code'
  },
  goToPassword: {
    event: 'interaction',
    action: 'click_text',
    category: 'repeat_customer_flow',
    label: 'login_password'
  },
  sendMagicLink: {
    event: 'interaction',
    action: 'click_text',
    category: 'repeat_customer_flow',
    label: 'email_link'
  }
};

export const MODEL_VALIDATION_ERROR = 'MODEL_VALIDATION_ERROR';
export const BUSINESS_LOGIC_ERROR = 'BUSINESS_LOGIC_ERROR';

export const IGNORE_ERROR_TYPES = ['Network'];

export const CATEGORY_QUESTION: JobFormQuestion = {
  id: CATEGORY_QUESTION_ID,
  inputType: 'AutoComplete',
  question: 'What service would you like done?',
  placeholder: 'e.g. Plumber, Cleaner, DJ',
  allowAttachments: false
};

export const LOCATION_QUESTION: JobFormQuestion = {
  id: LOCATION_QUESTION_ID,
  inputType: 'AutoComplete',
  question: '', // question needs to be updated with fetched category data
  placeholder: 'Enter your suburb or postcode',
  allowAttachments: false
};

export const DESCRIPTION_QUESTION: JobFormQuestion = {
  id: DESCRIPTION_QUESTION_ID,
  inputType: 'Textarea',
  question: 'Any further details?',
  placeholder: 'Add more information to increase the accuracy of your quotes',
  allowAttachments: true,
  clusterQuestion: false,
  clusteredById: null,
  clusterType: '',
  displayAs: 'Description:',
  rankInCluster: null,
  answers: [
    {
      id: '',
      answer: 'Further details',
      rank: 0,
      nextQuestionId: null,
      serviceTypeId: null,
      nextUrl: ''
    }
  ]
};

export const EMAIL_QUESTION: JobFormQuestion = {
  id: EMAIL_QUESTION_ID,
  inputType: 'Email',
  allowAttachments: false
};

export const MOBILE_VERIFICATION_QUESTION: JobFormQuestion = {
  id: MOBILE_VERIFICATION_ID,
  inputType: 'MobileVerification',
  allowAttachments: false
};

export const CONTACT_DETAILS_QUESTION: JobFormQuestion = {
  id: CONTACT_DETAILS_ID,
  inputType: 'ContactDetails',
  allowAttachments: false
};

export enum JobFormPage {
  Homepage = 'Homepage',
  BusinessDirectory = 'Business Directory',
  BusinessDirectoryCategoryPage = 'Business Directory Category Page',
  BusinessDirectoryLocationPage = 'Business Directory Location Page',
  CostGuideCentre = 'Cost Guide Centre',
  CostGuidePage = 'Cost Guide Page',
  InspirationCentre = 'Inspiration Centre',
  InspirationArticle = 'Inspiration Article',
  MyJobs = 'My Jobs',
  BusinessProfile = 'Business Profile'
}

export enum JobFormSection {
  HeroBanner = 'Hero Banner',
  HowOneflareWorks = 'How Oneflare Works',
  GetQuotesNow = 'Get Quotes Now',
  FinalBanner = 'Final Banner',
  DirectoryListing = 'Directory Listing',
  NavigationBar = 'Navigation Bar',
  WhatsNextOnYourToDoList = "What's next on your to-do list?"
}

export enum JobFormCtaText {
  GetFreeQuotes = 'Get free quotes',
  GetQuotes = 'Get quotes',
  GetQuotesNow = 'Get quotes now',
  Go = 'Go',
  PostAJob = 'Post a job'
}