import PropTypes from 'prop-types';

import { ButtonStyled } from '../styled/HeaderStickyStyled';

const PostJobCta = ({ onClick, useGetFreeQuoteText }) => (
  <ButtonStyled
    onClick={onClick}
    label={useGetFreeQuoteText ? 'Get free quotes' : 'Post a job'}
    kind="primary"
  />
);

PostJobCta.propTypes = {
  onClick: PropTypes.func,
  useGetFreeQuoteText: PropTypes.bool.isRequired
};

PostJobCta.defaultProps = {
  onClick: () => {}
};

export default PostJobCta;
