import { AlertContainer } from '@oneflare/flarekit/lib/components';
import type { NextPage } from 'next';
import { useMemo, useRef } from 'react';

import { PageContext } from '@context-providers';
import homePageServerProps from '@oneflare-server-side-props/_homePage';
import OneflareHome from 'pages/oneflare.com.au/Home';
import type { HomePageProps } from 'types/oneflare.com.au/home';

const HomePage: NextPage = (props: HomePageProps) => {
  const alertContainerRef = useRef<AlertContainer>();
    const initialContext = useMemo(
      () => ({ AlertContainer: alertContainerRef }),
      [alertContainerRef]
    );

  return  (
    <PageContext.Provider value={initialContext}>
      <AlertContainer className="alert" ref={alertContainerRef} />
      <OneflareHome {...props} />;
    </PageContext.Provider>
  );
};

export { HomePage as default,  homePageServerProps as getServerSideProps };
