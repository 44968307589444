import { AirtaskerRegion } from '@airtasker/kmp-fundamental';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { publicRuntimeConfig } from 'lib/utils/Environment';

import { AnonymousUserIdClientAdapter } from './anonymousUserId/anonymousUserIdClientAdapter';

interface TrackerPageData {
  path: string;
  referrer: string;
  search: string;
  title: string;
  url: string;
}

export interface Analytics {
  track(options: any): void;
  getPageData: () => TrackerPageData;
  getTimeZone: () => string;
  getRegionCode: () => string;
  segmentPageEvent?: () => void;
}

class AnalyticsUserManager {
  constructor(private tracker: AnalyticsBrowser) {
    this.tracker = tracker;
  }

  async identify(userId?: string): Promise<void> {
    try {
      const analyticsUser = await this.tracker.user();
      const anonymousId = new AnonymousUserIdClientAdapter().findOrCreateId({
        onNotFound: () => {
          // do nothing
        }
      });
      analyticsUser.anonymousId(anonymousId);
      if (userId) this.tracker.identify(userId);
    } catch (error) {
      DataDogRumAgent.addRumError(error, 'Oneflare | AnalyticsUserManager | identify');
    }
  }
}

/**
 * Oneflare Analytics class
 * This class is used to track events and page views using Segment Analytics
 *
 * See: https://segment.com/docs/connections/spec/ for more methods and options to extend this class
 */
class OneflareAnalytics implements Analytics {
  tracker: AnalyticsBrowser;
  static instance: OneflareAnalytics;
  analyticsUserManager: AnalyticsUserManager;

  constructor(userId?: string) {
    if (OneflareAnalytics.instance) {
      return OneflareAnalytics.instance;
    }

    if (typeof window == 'undefined') {
      console.error('Oneflare Analytics can only be initialized on the client side');
    } else {
      this.initBrowserAgent(userId);
    }
  }

  private initBrowserAgent(userId?: string) {
    this.tracker = AnalyticsBrowser.load({
      cdnURL: publicRuntimeConfig.NEXT_PUBLIC_SEGMENT_CDN_URL!,
      writeKey: publicRuntimeConfig.NEXT_PUBLIC_SEGMENT_WRITE_KEY!
    });
    this.analyticsUserManager = new AnalyticsUserManager(this.tracker);
    this.analyticsUserManager.identify(userId);
    OneflareAnalytics.instance = this;
  }

  track(options: any): void {
    const { event_name, ...rest } = options;
    this.tracker.track(event_name, {
      ...rest
    });
  }

  getPageData() {
    return {
      path: window.location.pathname,
      referrer: window.document.referrer,
      search: window.location.search,
      title: window.document.title,
      url: `${window.location.origin}${window.location.pathname}`
    };
  }

  getTimeZone() {
    return window.Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  getRegionCode() {
    return AirtaskerRegion.AUSTRALIA.airtaskerRegionCode;
  }

  segmentPageEvent() {
    // don't track if current page is `/get-free-quotes`
    if (window.location.pathname === '/get-free-quotes') {
      return;
    }
    this.tracker.page();
  }
}

export { OneflareAnalytics };
