import PropTypes from 'prop-types';

import { ButtonStyled } from '../styled/HeaderStickyStyled';

const DspCta = ({
  handleCallClick,
  directLeadsNumber
}) => {
  return (
    <ButtonStyled
      label={`Call ${directLeadsNumber}`}
      kind="primary"
      onClick={handleCallClick}
    />
  );
};

DspCta.propTypes = {
  directLeadsNumber: PropTypes.string,
  handleCallClick: PropTypes.func
};

DspCta.defaultProps = {
  handleCallClick: () => {},
  directLeadsNumber: null
};

export default DspCta;
