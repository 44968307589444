import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useContext, useEffect, useMemo, useState } from 'react';

import { PageContext } from '@context-providers';
import { PageViewType, useTrackPageView } from 'lib/analytics/trackPageViews';
import { JobFormCtaText, JobFormPage, JobFormSection } from 'lib/oneflare-job-form/utils/constants';
import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import HeadContent from 'shared/components/_oneflare/HeadContent/HeadContent';
import BottomBanner from 'shared/components/BottomBanner';
import Footer from 'shared/components/Footer';
import { useThemeContext } from 'shared/components/HighOrderComponent/withThemeProvider/withThemeProvider';
import LazyLoadedSection from 'shared/components/LazyLoadedSection';
import NotificationBanner from 'shared/components/NotificationBanner';
import { NotificationBannerMsgStyled } from 'shared/components/NotificationBanner/styled/NotificationBannerStyled';
import { NOTIFICATION_STATUS } from 'shared/components/NotificationBanner/utils';
import { CTA_TYPES } from 'shared/components/PageHeader/Assets';
import PageHeader, { HeaderKind } from 'shared/components/PageHeader/PageHeader';
import PopularCategories from 'shared/components/PopularCategories/PopularCategories';
import Reviews from 'shared/components/ReviewSection';
import { useBreakpoint, useWindowResize } from 'shared/utils/hooks';
import type { HomePageProps } from 'types/oneflare.com.au/home';
import type { User } from 'types/oneflare.com.au/user';

import HomepageHero from './components/HomepageHero/HomepageHero';
import WhatsLive from './components/WhatsLive';
import CostGuidesHome from './CostGuidesHome';
import { homePageHowItWorksBusiness, homePageHowItWorksCustomer } from './utils/constants';

// #region dynamic components
export const HowItWorks = dynamic(() => import('shared/components/HowItWorks/HowItWorks'), { ssr: false});
export const MobileLeads = dynamic(() => import('./components/MobileLeads/MobileLeads'), { ssr: false });
export const FooterCta = dynamic(() => import('./components/FooterCta/FooterCta'), { ssr: false });
// #endregion dynamic components

const CACHE_KEY_PREFIX = process.env.CACHE_KEY_PREFIX;

const bottomHead = (
  <>
    <link rel="stylesheet preload" href="https://cdn.oneflare.com/static/theme/2018/fonts/sf/sf-ui-display-thin.css" as="style" />
    <meta name="google" content="nositelinkssearchbox" />
  </>
);

const pageTracking = {
  environment: publicRuntimeConfig.ENVIRONMENT,
  deployment_version: CACHE_KEY_PREFIX,
  form_type: 'job_request',
  form_version: '3',
  form_step: '01'
};

const OneflareHome = ({
  params: {
    randomNumber = 1,
    invitation = '',
    staticRender = false
  },
  popularCategoriesData,
  whatsLiveData = []
}: HomePageProps) => {
  const [user, setUser] = useState<User | null>(null);
  useTrackPageView(PageViewType.Home, setUser);
  const { controller } = useContext(JobFormControllerContext);
  const theme = useThemeContext();
  const isDesktop = useBreakpoint('lg');
  const smBreakPoint = (theme as any).breakpoint.sm.min.split('px')[0];
  const IPHONE_X_WIDTH = smBreakPoint - 201;
  const viewportWidth = useWindowResize(0);
  const showBottomBanner = useMemo(() => (user && !user.isBusiness) && !isDesktop, [isDesktop, user]);

  const router = useRouter();

  const { AlertContainer } = useContext(PageContext);

  useEffect(() => {
    if (router.query && router.query.expired) {
      AlertContainer?.current.error({ message: 'This link has expired' });
    }
  }, [AlertContainer, router.query]);

  return (
    <>
      <HeadContent
        pageUrl={publicRuntimeConfig.SITE_URL}
        pageTitle="Oneflare: Connecting Australians With Experts &amp; Businesses"
        bottomHead={bottomHead}
        tracking={pageTracking}
      />
      {(invitation === 'expired' || invitation === 'revoked') && (
        <NotificationBanner status={NOTIFICATION_STATUS.ERROR}>
          <NotificationBannerMsgStyled>
            {`Your invitation to join has ${invitation === 'expired' ? 'expired' : 'been revoked'}, `}
            contact the business owner to arrange another invite.
          </NotificationBannerMsgStyled>
        </NotificationBanner>
      )}
      <PageHeader
        kind={HeaderKind.OVERLAY}
        ctaKind={CTA_TYPES.POST_JOB}
        openInAppStickyHeader
        mobileCta={{
          label: viewportWidth >= IPHONE_X_WIDTH ? 'Open in app' : 'Open app',
          link: '/oneflare-app?utm_campaign=header'
        }}
      />
      <HomepageHero randomNumber={randomNumber} />
      <LazyLoadedSection style={{ minHeight: '228px' }} autoLoad={staticRender} rootMargin="150px">
        <HowItWorks
          ctaButton={null}
          kind="threeColumn"
          panelData={homePageHowItWorksCustomer}
          mainTitle="How Oneflare works"
          mainTitleMobile="Need a job done?"
          mainDescription="Oneflare can connect you to the right business, first time and every time."
          mainDescriptionMobile="Oneflare can connect you to the right experts, first time and every time. Here’s how it works:"
          buttonLabel="Post a job"
          handleButtonClick={() => {
            controller?.open({page: JobFormPage.Homepage, section: JobFormSection.HowOneflareWorks, ctaText: JobFormCtaText.PostAJob});
          }}
        />
        <HowItWorks
          ctaButton={null}
          kind="threeColumn"
          panelData={homePageHowItWorksBusiness}
          mainTitle="Oneflare for Business"
          mainTitleMobile="Oneflare for Business"
          mainDescription="Start meeting customers on Oneflare. Browse leads and choose the customers you want to connect with."
          mainDescriptionMobile="Start meeting customers on Oneflare. Browse leads and choose the customers you want to connect with."
          buttonLabel="Register business"
          handleButtonClick={() => {
            window.location.href = '/businesses/new';
          }}
        />
      </LazyLoadedSection>
      <LazyLoadedSection style={{ minHeight: '228px' }} autoLoad={staticRender} rootMargin="150px">
        <MobileLeads />
      </LazyLoadedSection>
      <PopularCategories
        isBot={staticRender}
        popularCategoriesData={popularCategoriesData}
      />
      <WhatsLive whatsLiveData={whatsLiveData} />
      <Reviews
        summary="Every day, thousands of Australians come to Oneflare to make connections with the right experts"
      />
      <CostGuidesHome
        heading="Get the right price for your job"
        subHeading="The Oneflare Cost Guide Centre is your one-stop
      shop to help you set your budget;
      from smaller tasks to larger projects."
        showSeeMoreButton
        className=""
        showCostsHomeButton={false}
        isBot={staticRender}
      />
      <LazyLoadedSection style={{ minHeight: '288px' }} autoLoad={staticRender} rootMargin="150px">
        <FooterCta />
      </LazyLoadedSection>
      <LazyLoadedSection style={{ minHeight: '381px' }} autoLoad={staticRender} rootMargin="150px">
        <Footer />
      </LazyLoadedSection>
      {showBottomBanner && (<BottomBanner />)}
    </>
  );
};

export default OneflareHome;
