import { createContext } from 'react';

import { globalAlertContainer } from 'lib/alerts/globalAlertContainer';
import type { CategoryModel, DynamicPageDataModel, LocationModel } from 'types/shared';

interface PageContextModel {
  category?: CategoryModel | null;
  location?: LocationModel | null;
  params?: Record<string, string | string[]> | null;
  AlertContainer: typeof globalAlertContainer;
  dynamicPageData?: DynamicPageDataModel | null;
}

const PageContext = createContext<PageContextModel>({
  AlertContainer: globalAlertContainer,
  category: null,
  location: null,
  dynamicPageData: null,
  params: null
});

export { PageContext, type PageContextModel };
