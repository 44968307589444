import { ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

import { globalAlertContainer } from 'lib/alerts/globalAlertContainer';

import { APOLLO_MESSAGES } from '../constants/messages';

const successLink = new ApolloLink((operation, forward) => {
  const { successMessage } = operation.getContext();

  return forward(operation).map((response) => {
    if (successMessage && response.data) {
      const message = typeof successMessage === 'function'
        ? successMessage(response.data, operation)
        : successMessage;
      
        // If output of successMessage function is undefined, don't show
      message && globalAlertContainer.current?.success({ message });
    }
    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const { errorMessage, showDefaultErrorMessages } = operation.getContext();

  let message = typeof errorMessage === 'function'
    ? errorMessage({ graphQLErrors, networkError }, operation)
    : errorMessage;
  
  if (showDefaultErrorMessages && !message) {
    message = networkError 
      ? APOLLO_MESSAGES.errors.network
      : graphQLErrors && graphQLErrors[0]?.extensions?.code === 'UNAUTHORIZED'
        ? APOLLO_MESSAGES.errors.unauthorized
        : graphQLErrors && graphQLErrors[0]?.extensions?.code === 'FORBIDDEN'
          ? APOLLO_MESSAGES.errors.forbidden
          : APOLLO_MESSAGES.errors.default;
  } 

  // If output of errorMessage function is undefined, don't show
  message && globalAlertContainer.current?.error({ message });
});

// Compose the links
export const notificationLink = ApolloLink.from([errorLink, successLink]); 