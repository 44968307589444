
import WeddingHeader from '@oneflare/flarekit/lib/components/WeddingHeader';

import type { ICurrentUser } from 'types/oneflare.com.au/user';
import type { CategoryModel } from 'types/shared';

import PageHeaderOneflare from './components/PageHeaderOneflare/PageHeaderOneflare';
import { HeaderKind } from './PageHeader';

interface IHeaderVariants {
  DOMAIN: string;
  category: Partial<CategoryModel>;
  categoryId?: string | number;
  currentUser: ICurrentUser;
  hideGuestCta: boolean;
  isOneflareClient: {
    openClientJobForm: () => Promise<void>;
  };
  jobFormHost: string;
  kind: HeaderKind;
  mobileCta?: {
    link: string;
    label: string;
  };
  zoneId?: number;
}

const HeaderVariants = ({
  DOMAIN,
  category,
  categoryId,
  currentUser,
  hideGuestCta,
  jobFormHost,
  isOneflareClient,
  kind,
  mobileCta,
  zoneId
}: IHeaderVariants) => {
  switch (DOMAIN) {
    case 'wedding':
      return (
        <WeddingHeader
          category={category}
          currentPath="/account"
          isOneFlareClient={isOneflareClient}
          zoneId={zoneId}
          {...(categoryId && { categoryId: +categoryId })}
        />
      );
    default:
      return (
        <PageHeaderOneflare
          currentUser={currentUser}
          kind={kind}
          jobFormHost={jobFormHost}
          isOneflareClient={isOneflareClient}
          hideGuestCta={hideGuestCta}
          zoneId={zoneId}
          mobileCta={mobileCta}
          {...(categoryId && { categoryId: +categoryId })}
        />
      );
  }
};

export { HeaderVariants };
