import {isEmailValid} from 'shared/utils/validations';
import type {
  AutoCompleteUserAnswer,
  CheckboxUserAnswer,
  ContactDetail,
  CreatePendingJobAttrs,
  DatepickerNewUserAnswer,
  DatepickerUserAnswer,
  DropdownUserAnswer,
  Id,
  JobFormAnswer,
  JobFormInputType,
  JobFormQuestion,
  LocationUserAnswer,
  QuantifiersUserAnswer,
  RadioUserAnswer,
  SchedulerUserAnswer,
  TextUserAnswer,
  UserAnswer,
  UserAnswers,
  InputComponentUserAnswer
} from 'types/oneflare.com.au/jobForm';

import {
  BUSINESS_LOGIC_ERROR,
  CATEGORY_QUESTION,
  CATEGORY_QUESTION_ID,
  CONTACT_DETAILS_ID,
  CONTACT_DETAILS_QUESTION,
  DESCRIPTION_QUESTION,
  DESCRIPTION_QUESTION_ID,
  DESCRIPTION_QUESTION_TEXT,
  EMAIL_QUESTION,
  EMAIL_QUESTION_ID,
  GENERAL_QUESTION_IDS,
  LOCATION_QUESTION,
  LOCATION_QUESTION_ID,
  MODEL_VALIDATION_ERROR,
  OTHER_OPTION_LABEL,
  SCHEDULER_ANSWERS,
  SINGLE_DATE_OPTION,
  SPECIFIC_DATE_OPTION_LABEL,
  V2_CATEGORIES
} from './constants';


type FormartUserAnswers = (args: {
  descriptionQuestionId: string;
  email: string;
  questions: Array<JobFormQuestion>;
  userAnswers: UserAnswers;
}) => CreatePendingJobAttrs;

abstract class JobFormHelper {
  // #region - Handle keydown
  static handleTextareaKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation();
  };

  static moveForwardOnKeyDown = (
    e: KeyboardEvent,
    moveForwardAction: () => void,
    isKeyDownEnabled: boolean
  ) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (isKeyDownEnabled) moveForwardAction();
    }
  };
  // #endregion - Handle keydown

  // Extract state name from location autocomplete option, e.g. '2000, Sydney, NSW'
  static getGeographicalState = (location: string): string => {
    const lastWord = location.split(' ').pop();
    return lastWord;
  };

  // #region - Handle unique textfield inputs
  static setMaxInputLength = (questionString: string): number => {
    const isYear = questionString === 'Year';
    const isCarRego = (
      questionString === 'Number plate of vehicle'
      || questionString === 'What is your vehicle\'s number plate?'
    );

    switch (true) {
      case (isCarRego):
        return 7;
      case (isYear):
        return 4;
      default:
        return null;
    }
  };

  static setInputRegex = (questionString: string, input: string): string => {
    const isYear = questionString === 'Year';
    const isCarRego = (
      questionString === 'Number plate of vehicle'
      || questionString === 'What is your vehicle\'s number plate?'
    );

    switch (true) {
      case (isCarRego):
        // regex: remove whitespace at start of string,
        // non-alphanumeric characters, >=2 whitespaces.
        return input.toUpperCase().replace(/([^\d\sA-Z]|^\s)|\s(?=\s)/g, '');
      case (isYear):
        return input.replace(/\D/g, '');
      default:
        return input;
    }
  };
  // #endregion - Handle unique textfield inputs

  // #region - User answer validity checkers
  static checkIsYearValid = (year: string): boolean => {
    if (!year) return false;
    const currentYear = new Date().getFullYear();
    return +year > 1900 && +year <= currentYear + 1;
  };

  static checkIsEmailValid = (userAnswer: string): boolean => {
    return Boolean(userAnswer) && isEmailValid(userAnswer);
  };

  static checkIsCheckboxValid = (userAnswer: CheckboxUserAnswer = {}): boolean => {
    const values = Object.values(userAnswer);
    return (
      // At least one checkbox must be checked
      values.some(({ checked }) => checked)
      // A checked checkbox must have valid value
      && values.every(({ checked, value }) => (checked ? Boolean(value) : true))
    );
  };

  static checkIsContactDetailsValid = (userAnswer: { name: string; phone: string }): boolean => {
    const isValidUserAnswer = (obj: { name: string; phone: string }): obj is { name: string; phone: string } => {
      return obj && typeof obj.name === 'string' && typeof obj.phone === 'string';
    };
    if (!isValidUserAnswer(userAnswer)) return false;
    return Object.values(userAnswer).every(value => value !== null && value !== undefined && value !== '');
  };

  static checkIsDatepickerNewValid = (userAnswer: DatepickerNewUserAnswer): boolean => {
    const { value, date, dateRange } = userAnswer;
    const isDateRangeValid = dateRange.every((item) => item);
    if (value === SINGLE_DATE_OPTION) return Boolean(date);
    return isDateRangeValid;
  };

  static checkIsTextfieldValid = (value: string, questionId: Id): boolean => {
    // '67' is id of question 'Year' for Mechanic category
    if (questionId === '67') return this.checkIsYearValid(value);
    return Boolean(value);
  };

  static checkIsSchedulerValid = (userAnswer: SchedulerUserAnswer): boolean => {
    const { date, value } = userAnswer;
    if (value === SPECIFIC_DATE_OPTION_LABEL) {
      return Boolean(date);
    }
    return Boolean(value);
  };

  static checkIsUserAnswerValid = (
    inputType: JobFormInputType,
    userAnswer: InputComponentUserAnswer,
    questionId: Id
  ): boolean => {
    switch (inputType) {
      case 'AutoComplete':
      case 'Datepicker':
      case 'Dropdown':
      case 'Location':
      case 'Radio':
        return Boolean((userAnswer as RadioUserAnswer)?.value);
      case 'Checkbox':
        return this.checkIsCheckboxValid(userAnswer as CheckboxUserAnswer);
      case 'ContactDetails':
        return this.checkIsContactDetailsValid(userAnswer as ContactDetail);
      case 'MobileVerification':
        return false;
      case 'Email':
        return this.checkIsEmailValid(userAnswer as string);
      case 'Scheduler':
        return this.checkIsSchedulerValid(userAnswer as SchedulerUserAnswer);
      case 'Textfield':
        return this.checkIsTextfieldValid((userAnswer as TextUserAnswer)?.value, questionId);
      // Textarea and Quantifiers are optional
      default:
        return true;
    }
  };

  static checkIsClusteredUserAnswersValid = (
    clusteredUserAnswers: UserAnswers = {}
  ): Record<string, boolean> => {
    const entries = Object.entries(clusteredUserAnswers);
    return entries.reduce((prev, current) => {
      const [questionId, { inputType, userAnswer }] = current;
      return {
        ...prev,
        [questionId]: this.checkIsUserAnswerValid(inputType, userAnswer, questionId)
      };
    }, {});
  };
  // #endregion - User answer validity checkers

  // #region - Format cluster question related data
  static formatAllClusteredQuestions = (
    allQuestions: Array<JobFormQuestion>
  ): Record<string, Array<JobFormQuestion>> => {
    const clusteredQuestions = allQuestions.filter(({ clusteredById }) => clusteredById);
    return clusteredQuestions.reduce((prev, current) => ({
      ...prev,
      [current.clusteredById]: [...(prev[current.clusteredById] || []), current]
        .sort((a, b) => a.rankInCluster - b.rankInCluster)
    }), {});
  };

  static formatAllClusteredUserAnswers = (
    allClusteredQuestions: Record<string, Array<JobFormQuestion>>,
    allUserAnswers: UserAnswers
  ): Record<string, UserAnswers> => {
    return Object.entries(allClusteredQuestions).reduce((prev, current) => {
      const [parentAnswerId, clusteredQuestions] = current;
      const clusteredQuestionIds = clusteredQuestions.map(({ id }) => id);
      return {
        ...prev,
        [parentAnswerId]: Object.entries(allUserAnswers).reduce((prev, current) => {
          const [questionId, userAnswerItem] = current;
          return clusteredQuestionIds.includes(questionId) ? {
            ...prev,
            [questionId]: userAnswerItem
          } : prev;
        }, {})
      };
    }, {});
  };
  // #endregion - Format cluster question related data

  static formatDatepickerUserAnswer = (userAnswer: DatepickerUserAnswer): string => {
    const formattedDate = userAnswer.value.format('[the] Do [of] MMM YYYY');
    return `On ${formattedDate}`;
  };

  static formatDatepickerNewUserAnswer = (userAnswer: DatepickerNewUserAnswer): string => {
    const { date, dateRange, value } = userAnswer;
    if (value === SINGLE_DATE_OPTION) {
      const formattedDate = date.format('[the] Do [of] MMM YYYY');
      return `On ${formattedDate}`;
    }
    const formattedDateRange = dateRange.map((date) => date.format('[the] Do [of] MMM YYYY'));
    return `Between ${formattedDateRange[0]} and ${formattedDateRange[1]}`; // TODO: need confirmation for wording
  };

  static formatSchedulerUserAnswer = (userAnswer: SchedulerUserAnswer): string => {
    const { date, isFlexible, value } = userAnswer;
    if (value === SPECIFIC_DATE_OPTION_LABEL) {
      const formattedDate = date.format('[the] Do [of] MMM YYYY');
      return `${isFlexible ? 'Around' : 'On'} ${formattedDate}`;
    }
    return value;
  };

  static updateJobFormQuestions = (
    locationQuestion: JobFormQuestion,
    jobFormQuestions: Array<JobFormQuestion>,
    isLoggedIn = false,
    flag?: string
  ) => {
    // Find & store id of Description question to help with formatting user answers for submission
    const descriptionQuestion = jobFormQuestions?.find((item) => item.question === DESCRIPTION_QUESTION_TEXT);
    const descriptionQuestionId = descriptionQuestion ? descriptionQuestion.id : DESCRIPTION_QUESTION_ID;

    // #region - manually update questions fetched from old database
    // Change `rank` from 1-based into 0-based
    const updatedJobFormQuestions: Array<JobFormQuestion> = jobFormQuestions.map((item) => ({
      ...item,
      rank: item.rank - 1,
      answers: item.answers.map((item) => ({ ...item, rank: item.rank - 1 }))
    }));

    // Add Description question
    updatedJobFormQuestions.push(DESCRIPTION_QUESTION);
    // #endregion - manually update questions fetched from old database

    const authenticationQuestions = isLoggedIn ? [] : [EMAIL_QUESTION, CONTACT_DETAILS_QUESTION];
    const questionsWithoutCategory = [
      locationQuestion,
      // If Description question is available, questions are fetched from new database
      ...(descriptionQuestion ? jobFormQuestions : updatedJobFormQuestions),
      ...authenticationQuestions
    ];
    return {
      questions: flag ? questionsWithoutCategory : [CATEGORY_QUESTION, ...questionsWithoutCategory],
      descriptionQuestionId
    };
  };

  static initializeUserAnswer = (
    answers: Array<JobFormAnswer>,
    inputType: JobFormInputType
  ) => {
    switch (inputType) {
      case 'AutoComplete':
        return { name: '', value: '' } as AutoCompleteUserAnswer;
      case 'Checkbox':
        return answers.reduce((prev, current) => {
          const {
            answer, id, nextQuestionId, rank, serviceTypeId
          } = current;
          return {
            ...prev,
            [id]: {
              checked: false,
              nextQuestionId,
              rank,
              serviceTypeId,
              value: answer.toLowerCase() === OTHER_OPTION_LABEL.toLowerCase() ? '' : answer
            }
          };
        }, {}) as CheckboxUserAnswer;
      case 'Datepicker': {
        const [firstAnswer] = answers;
        return {
          nextQuestionId: firstAnswer.nextQuestionId,
          value: null
        } as DatepickerUserAnswer;
      }
      case 'Dropdown': {
        const [firstAnswer] = answers;
        return {
          nextQuestionId: firstAnswer.nextQuestionId,
          serviceTypeId: firstAnswer.serviceTypeId,
          value: firstAnswer.answer
        } as DropdownUserAnswer;
      }
      case 'Location': {
        const [firstAnswer] = answers;
        return {
          name: '',
          nextQuestionId: firstAnswer.nextQuestionId,
          serviceTypeId: firstAnswer.serviceTypeId,
          value: ''
        } as LocationUserAnswer;
      }
      case 'Quantifiers': {
        const [firstAnswer] = answers;
        return answers.reduce((prev, current) => {
          const { answer, id, serviceTypeId } = current;
          return {
            ...prev,
            [id]: {
              name: answer,
              nextQuestionId: firstAnswer.nextQuestionId,
              serviceTypeId,
              value: 0
            }
          };
        }, {}) as QuantifiersUserAnswer;
      }
      case 'Radio': {
        const [firstAnswer] = answers;
        return {
          answerId: firstAnswer.id,
          nextQuestionId: firstAnswer.nextQuestionId,
          rank: firstAnswer.rank,
          serviceTypeId: firstAnswer.serviceTypeId,
          value: firstAnswer.answer,
          nextUrl: firstAnswer.nextUrl
        } as RadioUserAnswer;
      }
      case 'Scheduler': {
        const [firstAnswer] = answers;
        return {
          answerId: firstAnswer.id,
          date: null,
          isFlexible: false,
          nextQuestionId: firstAnswer.nextQuestionId,
          serviceTypeId: firstAnswer.serviceTypeId,
          value: SCHEDULER_ANSWERS[0]
        } as SchedulerUserAnswer;
      }
      case 'Textarea':
      case 'Textfield': {
        const [firstAnswer] = answers;
        return {
          nextQuestionId: firstAnswer.nextQuestionId,
          value: ''
        } as TextUserAnswer;
      }
      default:
        return '';
    }
  };

  static initializeAllUserAnswers = (
    categoryUserAnswer: AutoCompleteUserAnswer,
    locationUserAnswer: AutoCompleteUserAnswer,
    questions: Array<JobFormQuestion>
  ): UserAnswers => {
    const getInitializedUserAnswer = (id: Id, answers: Array<JobFormAnswer>, inputType: JobFormInputType) => {
      switch (id) {
        case CATEGORY_QUESTION_ID:
          return categoryUserAnswer;
        case LOCATION_QUESTION_ID:
          return locationUserAnswer;
        default:
          return this.initializeUserAnswer(answers, inputType);
      }
    };

    // Add category question and location question back for initializing user answers
    // in case they are omitted when job form is loaded initially
    const questionsForInitAnswers = [...questions, CATEGORY_QUESTION, LOCATION_QUESTION];
    const initializedUserAnswers = questionsForInitAnswers.reduce((prev, current) => {
      const {
        answers, clusteredById, clusterQuestion, id, inputType
      } = current;
      return {
        ...prev,
        [id]: {
          attachments: [],
          clusteredById,
          clusterQuestion,
          inputType,
          userAnswer: getInitializedUserAnswer(id, answers, inputType)
        }
      };
    }, {});

    return initializedUserAnswers;
  };

  static formatUserAnswer = (
    userAnswer: UserAnswer |
      RadioUserAnswer |
      CheckboxUserAnswer |
      DatepickerUserAnswer |
      QuantifiersUserAnswer |
      SchedulerUserAnswer |
      AutoCompleteUserAnswer |
      DropdownUserAnswer |
      LocationUserAnswer,
    inputType: JobFormInputType
  ): [string[] | string | number, Array<number>] => {
    switch (inputType) {
      case 'AutoComplete':
      case 'Dropdown':
      case 'Radio':
        return [
          (userAnswer as RadioUserAnswer).value,
          [(userAnswer as RadioUserAnswer)?.serviceTypeId].filter((serviceTypeId) => serviceTypeId)
        ];
      case 'Checkbox':
        return [
          Object.values(userAnswer).filter(({ checked }) => checked).map(({ value }) => value),
          Object.values(userAnswer).filter(({ checked, serviceTypeId }) => checked && serviceTypeId)
            .map(({ serviceTypeId }) => serviceTypeId)
        ];
      case 'Datepicker':
        return [JobFormHelper.formatDatepickerUserAnswer(userAnswer as DatepickerUserAnswer), []];
      case 'Location':
        return [
          (userAnswer as LocationUserAnswer).name,
          [(userAnswer as LocationUserAnswer)?.serviceTypeId].filter((serviceTypeId) => serviceTypeId)
        ];
      case 'Quantifiers':
        return [
          Object.values(userAnswer).map(({ name, value }) => `${name}: ${value}`),
          Object.values(userAnswer).filter(({ serviceTypeId, value }) => serviceTypeId && value).map(({ serviceTypeId }) => serviceTypeId)
        ];
      case 'Scheduler':
        return [JobFormHelper.formatSchedulerUserAnswer(userAnswer as SchedulerUserAnswer), []];
      default:
        return [(userAnswer as UserAnswer)?.value, []];
    }
  };

  static formatAllUserAnswers: FormartUserAnswers = ({
    descriptionQuestionId,
    email,
    questions,
    userAnswers
  }) => {
    const pendingJobAttrs = {
      categoryId: null,
      locationId: null,
      email: null,
      jobData: {
        job: {
          attachments: [],
          description: '',
          locationId: null,
          questions: []
        },
        jobFormV2: false,
        jobFormOrigin: 'nextjs',
        serviceTypeIds: []
      }
    };

    // Add category question and location question back for filtering user answers
    // in case they are omitted
    const questionsForFilterAnswers = [...questions, CATEGORY_QUESTION, LOCATION_QUESTION];
    const questionIds = questionsForFilterAnswers.map(({ id }) => id);
    const filteredUserAnswers: UserAnswers = questionIds.reduce((prev, current) => {
      return { ...prev, [current]: userAnswers[current] };
    }, {});

    const parentAnswers = Object.values(filteredUserAnswers)
      .filter(({ clusterQuestion }) => clusterQuestion)
      .map(({ userAnswer }) => String((userAnswer as UserAnswer).answerId));

    Object.entries(filteredUserAnswers).forEach((item) => {
      const [questionId, {
        attachments, clusteredById, clusterQuestion, inputType, userAnswer
      }] = item;
      const userAnswerAndServiceTypeIds = JobFormHelper.formatUserAnswer(userAnswer as UserAnswer, inputType);
      const formattedUserAnswer = userAnswerAndServiceTypeIds[0];
      const serviceTypeIds = userAnswerAndServiceTypeIds[1]
        .filter((item) => item)
        .map((item) => String(item));
      const formattedAttachments = attachments?.map(({ thumb }) => thumb);
      switch (true) {
        case questionId === CATEGORY_QUESTION_ID:
          pendingJobAttrs.categoryId = Number(formattedUserAnswer);
          pendingJobAttrs.jobData.jobFormV2 = V2_CATEGORIES.includes(String(formattedUserAnswer));
          break;
        case questionId === LOCATION_QUESTION_ID:
          pendingJobAttrs.locationId = Number(formattedUserAnswer);
          pendingJobAttrs.jobData.job.locationId = String(formattedUserAnswer);
          break;
        case questionId === EMAIL_QUESTION_ID:
          pendingJobAttrs.email = email;
          break;
        case questionId === descriptionQuestionId:
          pendingJobAttrs.jobData.job.description = formattedUserAnswer as string;
          pendingJobAttrs.jobData.job.attachments = formattedAttachments;
          break;
        case questionId === CONTACT_DETAILS_ID:
        case clusterQuestion:
        case clusteredById && !parentAnswers.includes(String(clusteredById)):
          break;
        default:
          pendingJobAttrs.jobData.job.questions.push({
            answer: Array.isArray(formattedUserAnswer) ? '' : formattedUserAnswer,
            answers: Array.isArray(formattedUserAnswer) ? formattedUserAnswer : [],
            attachments: formattedAttachments,
            questionId
          });
          pendingJobAttrs.jobData.serviceTypeIds.push(...serviceTypeIds);
      }
    });

    pendingJobAttrs.jobData.serviceTypeIds = Array.from(new Set(pendingJobAttrs.jobData.serviceTypeIds));

    return pendingJobAttrs;
  };

  static getNextQuestion = ({
    clusteredQuestions,
    clusteredUserAnswers,
    clusterQuestion,
    nonClusteredInputType,
    nonClusteredUserAnswer,
    questions
  }: {
    clusteredQuestions: Array<JobFormQuestion>;
    clusteredUserAnswers: UserAnswers;
    clusterQuestion: boolean;
    nonClusteredInputType: JobFormInputType;
    nonClusteredUserAnswer: UserAnswer;
    questions: Array<JobFormQuestion>;
  }): JobFormQuestion => {
    let inputType;
    let nextQuestionId;
    let userAnswer;

    if (clusterQuestion) {
      const lastClusteredQuestion = [...clusteredQuestions].pop();
      const lastClusteredUserAnswer = clusteredUserAnswers[lastClusteredQuestion.id];
      inputType = lastClusteredUserAnswer.inputType;
      userAnswer = lastClusteredUserAnswer.userAnswer;
    } else {
      inputType = nonClusteredInputType;
      userAnswer = nonClusteredUserAnswer;
    }

    switch (inputType) {
      case 'Checkbox':
      case 'Quantifiers':
        nextQuestionId = Object.values(
          userAnswer as CheckboxUserAnswer | QuantifiersUserAnswer
        )[0].nextQuestionId;
        break;
      default:
        nextQuestionId = userAnswer?.nextQuestionId;
        break;
    }

    return questions.find(({id}) => id === String(nextQuestionId));
  };

  // Filter all questions based on nextQuestionId
  static filterQuestions = (
    questions: Array<JobFormQuestion>,
    userAnswers: UserAnswers
  ): Array<JobFormQuestion> => {
    const allClusteredQuestionsFormatted = this.formatAllClusteredQuestions(questions);
    const allClusteredUserAnswers = this.formatAllClusteredUserAnswers(
      allClusteredQuestionsFormatted,
      userAnswers
    );
    const allClusteredQuestions = questions.filter(({ clusteredById }) => clusteredById);
    const nonClusteredQuestions = questions.filter(({ clusteredById }) => !clusteredById);

    const filteredQuestions = nonClusteredQuestions.reduce((prev, current) => {
      const lastQuestion = [...prev].pop();
      const nonClusteredUserAnswer = userAnswers[lastQuestion?.id]?.userAnswer;
      const nextQuestion = this.getNextQuestion({
        clusteredQuestions: allClusteredQuestionsFormatted[(nonClusteredUserAnswer as UserAnswer)?.answerId],
        clusteredUserAnswers: allClusteredUserAnswers[(nonClusteredUserAnswer as UserAnswer)?.answerId],
        clusterQuestion: lastQuestion?.clusterQuestion,
        nonClusteredInputType: lastQuestion?.inputType,
        nonClusteredUserAnswer: nonClusteredUserAnswer as UserAnswer,
        questions
      });

      switch (true) {
        case GENERAL_QUESTION_IDS.includes(String(current.id)):
        case current.rank === 0:
          return [...prev, current];
        case current.rank === 1:
          return [...prev, current];
        default:
          return [...prev, ...(nextQuestion ? [nextQuestion] : [])];
      }
    }, []);
    return [...filteredQuestions, ...allClusteredQuestions];
  };

  static getExitConfirmationCard = ({ cancelAction, confirmAction }: {
    cancelAction: () => void;
    confirmAction: () => void;
  }) => {
    return {
      title: 'Are you sure you want to leave?',
      message: 'Closing the form will erase all the answers you\'ve provided',
      cancelButton: {
        label: 'Resume',
        action: cancelAction
      },
      confirmButton: {
        label: 'Leave',
        action: confirmAction
      }
    };
  };

  static canIgnoreError = ({ code }: { code?: string }): boolean => {
    return code === MODEL_VALIDATION_ERROR || code === BUSINESS_LOGIC_ERROR;
  };

  static getErrorMessage = (error?: string) => {
    const defaultErrorMessage = 'Something went wrong. Please try again later.';
    if (!error) return defaultErrorMessage;
    const [issueType, errorMessage] = error.split(':');
    if (!errorMessage) return defaultErrorMessage;

    const trimmedErrorMessage = errorMessage.trim();
    if (['Validation failed', 'Invalid input'].includes(issueType)) {
      if (trimmedErrorMessage === 'Phone number is blacklisted') return 'This phone number cannot be used. Please contact support';
      return trimmedErrorMessage;
    }
    return defaultErrorMessage;
  };
}

export default JobFormHelper;
