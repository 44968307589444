import { gql } from '@apollo/client';

export const AUTOCOMPLETE_LOCATION = gql`
  query AutocompleteLocation($term: String!) {
    autocompleteLocation(term: $term) {
      id
      value
      suburb
      state
      postcode
    }
  }
`;

export type AutoCompleteLocationResponse = {
  autocompleteLocation: Array<{
    id: string;
    postcode: string;
    state: string;
    suburb: string;
    value: string;
  }>;
};
