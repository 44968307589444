import GuestHeader from '@oneflare/flarekit/lib/components/GuestHeader';
import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';

import { publicRuntimeConfig } from 'lib/utils/Environment';
import NotificationBanner from 'shared/components/NotificationBanner';
import { NotificationBannerMsgStyled } from 'shared/components/NotificationBanner/styled/NotificationBannerStyled';
import { NOTIFICATION_STATUS } from 'shared/components/NotificationBanner/utils';
import { useBreakpoint } from 'shared/utils/hooks';
import type { ICurrentUser } from 'types/oneflare.com.au/user';

import { HeaderKind } from '../../PageHeader';
import { PageHeaderStyled } from '../../styled/PageHeader';

type PageHeaderOneflareProps = {
  currentUser: ICurrentUser;
  kind?: HeaderKind;
  categoryId: number | string;
  jobFormHost: string;
  hideGuestCta: boolean;
  isOneflareClient: {
    openClientJobForm: () => Promise<void>;
  };
  zoneId?: number;
  mobileCta?: {
    link: string;
    label: string;
  };
}

const PageHeaderOneflare  = ({
  currentUser,
  kind,
  hideGuestCta,
  isOneflareClient,
  zoneId,
  categoryId,
  jobFormHost,
  mobileCta
}: PageHeaderOneflareProps) => {
  const { ENVIRONMENT } = publicRuntimeConfig;
  const lg = useBreakpoint('lg');
  const router = useRouter();
  const asPath = router.asPath;
  const kindMemo = useMemo((): HeaderKind => {
    if (currentUser && currentUser.user && lg) {
      return HeaderKind.DEFAULT;
    }
    return kind;
  }, [currentUser, lg, kind]);

  const getFilteredNotifications = useMemo(() => {
    if (currentUser && currentUser.notifications && currentUser.user.business) {
      const isInstantAccessEnabled = currentUser.user.business.instantAccessEnabled;
      return isInstantAccessEnabled
        ? currentUser.notifications.filter((notification) => notification.type !== 'alert')
        : currentUser.notifications;
    }
    return [];
  }, [currentUser]);

  return (
    <>
      {kindMemo === 'overlay' || (currentUser && currentUser.user) ? (
        <>
          {getFilteredNotifications.map((notification) => (
            <NotificationBanner
              status={NOTIFICATION_STATUS[notification.type.toUpperCase()]}
              key={notification.message}
              closable={notification.closable}
              action={notification.action}
            >
              <NotificationBannerMsgStyled
                dangerouslySetInnerHTML={{
                  __html: notification.message
                }}
              />
            </NotificationBanner>
          ))}
          <PageHeaderStyled
            {...currentUser}
            {...(currentUser && currentUser.user?.isBusiness && { mobileCta })}
            {...(currentUser && currentUser.trueUser && { trueUser: currentUser.trueUser.name })}
            currentPath={asPath}
            kind={kindMemo}
            withCobranding
            jobFormHost={jobFormHost}
            isOneFlareClient={isOneflareClient}
            currentEnv={ENVIRONMENT}
          />
        </>
      ) : (
        <GuestHeader
          withCobranding
          categoryId={+categoryId}
          zoneId={zoneId}
          hideCta={hideGuestCta}
        />
      )}
    </>
  );
};

export default memo(PageHeaderOneflare);
