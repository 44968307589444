import Cobranding from '@oneflare/flarekit/lib/components/logos/Cobranding';
import { styled } from 'styled-components';

export const StickyHeaderStyled = styled.section<{
  $showStickyHeader?: boolean;
  $offset?: number;
}>`
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08);
  padding: 0;
  position: fixed;
  top: ${({ $showStickyHeader, $offset }) => ($showStickyHeader ? 0 : `-${$offset}px`)};
  transition: top 0.8s ease-in;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex[5]};
`;

export const ContainerStyled = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin: 16px 0;
`;

export const CobrandingLogoStyled = styled(Cobranding).attrs(({ theme }) => ({
  fill: theme.color.gunMetal
}))`
  width: 117px;
`;

export const LogoStyled = styled.div`
  align-items: center;
  display: flex;
  padding: 0;
`;
