import isEmpty from 'lodash/isEmpty';
import {
  type Dispatch,
  type SetStateAction,
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
  memo
} from 'react';
import { isMobile } from 'react-device-detect';

import { slugHelper, capitalizeFirstLetter } from 'shared/utils/helpers';
import type { CategoryModel } from 'types/shared';

import * as Styled from './styled/PopularCategories';

type City = {
  categories: Array<{
    averageRating: {
      rating: number;
      reviewsCount: number;
    };
    category: Pick<CategoryModel, 'cachedSlug' | 'name' | 'jobName' | 'name' | 'noun' | 'plural'>;
    image: {
      alt: string;
      thumbnail: string;
      title: string;
      url: string;
    };
  }>;
  city: string;
  location: {
    state: string;
    suburb: string;
  };
};

type PopularCategoriesProps = {
  isBot?: boolean;
  $maxTiles?: number;
  popularCategoriesData: {
    cities: Array<City>;
    ipDetectedCity: string;
  };
};

// cachedSlug - how category information is stored in the db for backend
// slug - returns the same as cachedSlug
// name - for frontend ie "Air Conditioning Experts" vs "air-conditioning Experts"
const PopularCategories = ({
  isBot = true,
  $maxTiles = 8,
  popularCategoriesData: {
    cities = [],
    ipDetectedCity = null
  }
}: PopularCategoriesProps) => {
  const domRef = useRef();
  const maxTiles = isMobile ? 4 : $maxTiles;
  const [isVisible, setVisible] = useState(true);

  useEffect(() => {
    const currentRef = domRef.current;
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setVisible(entry.isIntersecting);
      });
    });
    observer.observe(currentRef);
    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [domRef]);

  const [activeTab, setActiveTab] = useState('all');
  const [hideOtherCategories, setHideOtherCategories] = useState(false);
  const toggleLabel = hideOtherCategories ? 'less' : 'more';

  const handleHideOtherCategories = () => {
    setHideOtherCategories(!hideOtherCategories);
  };

  // #region show active tab based on ip
  const [ipDetected, setIpDetected] = useState('');
  useEffect(() => {
    if (!ipDetected) {
      setActiveTab('all');
    } else {
      setActiveTab(ipDetected);
    }
  }, [ipDetected]);

  const handleIpCity = useCallback((city) => {
    setIpDetected(city);
  },
  [setIpDetected]);

  useEffect(() => {
    if (ipDetectedCity) {
      handleIpCity(ipDetectedCity);
    }
  }, [ipDetectedCity, handleIpCity]);
  // #endregion show active tab based on ip

  const activeTabContent = useMemo(() => {
    return cities.filter((item) => item.city === activeTab);
  }, [activeTab, cities]);

  const otherPopularCategories = isBot ? cities : activeTabContent;

  return (
    <Styled.PopCatContainerStyled ref={domRef} $isVisible={isVisible}>
      <Styled.HeaderStyled>Browse popular categories</Styled.HeaderStyled>
      {!isEmpty(cities) && (
        <>
          <TabMenu
            activeTab={activeTab}
            cities={cities}
            setActiveTab={setActiveTab}
          />
          <Styled.PopularCategoriesWrapper>
            {activeTabContent.map((selectedCity) => (
              <RenderPopularCategoryTiles
                key={selectedCity.city}
                selectedCity={selectedCity}
                maxTiles={maxTiles}
                activeTab={activeTab}
              />
            ))}
          </Styled.PopularCategoriesWrapper>
          {otherPopularCategories.map((item) => (
            <Styled.OtherPopularCategoriesWrapper
              data-testid="other-popular-category-wrapper"
              className={!isBot && !hideOtherCategories && 'hide'}
              key={item.city}
            >
              <Styled.OtherPopularCategoriesHeader>
                {isBot ? item.city : 'Other popular categories'}
              </Styled.OtherPopularCategoriesHeader>
              <RenderOtherPopularCategoryLinks
                selectedCity={item} isBot={isBot} maxTiles={maxTiles}
              />
            </Styled.OtherPopularCategoriesWrapper>
          ))}
          {!!otherPopularCategories.length && (
            <Styled.SeeMoreOrLessButton
              kind="default-sm"
              label={`See ${toggleLabel} categories`}
              onClick={() => handleHideOtherCategories()}
            />
          )}
        </>
      )}
    </Styled.PopCatContainerStyled>
  );
};

type TabMenuProps = {
  activeTab: string;
  setActiveTab: Dispatch<SetStateAction<string>>;
  cities: PopularCategoriesProps['popularCategoriesData']['cities'];
};

function TabMenu({ cities, activeTab, setActiveTab }: TabMenuProps) {
  return (
    <Styled.TabMenuContainer>
      {cities.map((item) => {
        return (
          <Styled.TabMenuAnchor
            key={item.city}
            onClick={(e) => setActiveTab(e.target.text)}
            className={activeTab === item.city ? 'active' : ''}
            value={item.city}
            $active={activeTab === item.city}
          >
            {item.city}
          </Styled.TabMenuAnchor>
        );
      })}
    </Styled.TabMenuContainer>
  );
}

type RenderCategoriesProps = {
  selectedCity: City;
  maxTiles: number;
  activeTab: string;
};

function RenderPopularCategoryTiles({
  selectedCity,
  maxTiles,
  activeTab
}: RenderCategoriesProps) {
  const { categories, location, city } = selectedCity;
  const renderedCategories = useMemo(() => {
    return categories.slice(0, maxTiles).map((item) => {
      const {
        category: { cachedSlug, name: categoryName },
        averageRating,
        image: {
          url: categoryImageUrl
        } = { categoryImageUrl: '' }
      } = item;
      const locationSlug = !isEmpty(location) ? `${location.state}/${location.suburb}` : '';
      const cardTitle = city === 'all' ? categoryName : `${categoryName} ${city}`;
      const categoryUrl = `${cachedSlug}${locationSlug && `/${slugHelper(locationSlug)}`}`;
      const reviewText = `(${averageRating.reviewsCount.toLocaleString()} reviews ${city !== 'all' ? `in ${capitalizeFirstLetter(activeTab)}` : ''})`;

      return {
        cardTitle,
        categoryUrl,
        reviewText,
        categoryName,
        averageRating,
        categoryImageUrl
      };
    });
  }, [activeTab, categories, city, location, maxTiles]);

  return renderedCategories.map(
    ({
      cardTitle,
      categoryUrl,
      reviewText,
      categoryName,
      averageRating,
      categoryImageUrl
    }) => (
      <Styled.CategoryTile href={categoryUrl} key={categoryName} data-testid="category-tile">
        <Styled.CategoryImageWrapper>
          <Styled.CategoryImage
            alt={categoryName}
            src={categoryImageUrl}
            fill
            quality={100}
          />
        </Styled.CategoryImageWrapper>
        <Styled.CategoryHeader>{cardTitle}</Styled.CategoryHeader>
        <Styled.CategoryRating>
          {averageRating.rating}
          <Styled.RatingIcon kind="solid" />
          <Styled.CategoryReviews>{reviewText}</Styled.CategoryReviews>
        </Styled.CategoryRating>
      </Styled.CategoryTile>
    )
  );
}

function RenderOtherPopularCategoryLinks({
  selectedCity,
  maxTiles,
  isBot
}: Pick<RenderCategoriesProps, 'selectedCity' | 'maxTiles'> & {
  isBot?: boolean;
}) {
  const { categories, location, city } = selectedCity;
  const formattedCategories = isBot ? categories : categories.slice(maxTiles, categories.length);
  const renderedCategories = useMemo(() => {
    return formattedCategories.map((item) => {
      const { category: {
        cachedSlug,
        name: categoryName
      }} = item;

      const locationSlug = !isEmpty(location) ? `${location.state}/${location.suburb}` : '';

      if (!categoryName) return null;
      return {
        cachedSlug,
        categoryName,
        locationSlug
      };
    });
  }, [formattedCategories, location]);

  return (
    <>
      {renderedCategories.map(({ cachedSlug, categoryName, locationSlug }) => (
        <Styled.OtherPopularCategoryLink
          key={cachedSlug} data-testid="other-popular-category"
          href={`${cachedSlug}${locationSlug && `/${slugHelper(locationSlug)}`}`}
        >
          {city === 'all' ? categoryName : `${categoryName} ${city}`}
        </Styled.OtherPopularCategoryLink>
      ))}
    </>
  );
}

export default memo(PopularCategories);
