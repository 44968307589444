import ButtonLink from '@oneflare/flarekit/lib/components/ButtonLink';
import { useCallback, useState, useEffect } from 'react';

import { useThemeContext } from 'shared/components/HighOrderComponent/withThemeProvider/withThemeProvider';
import { useWindowResize } from 'shared/utils/hooks';

import * as Styled from './styled/OpenInAppStickyHeader';

const HEADER_HEIGHT = 72;
const NOTIFICATION_BANNER_HEIGHT = 80;

const OpenInAppStickyHeader = () => {
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const viewportWidth = useWindowResize(0);
  const threshold = HEADER_HEIGHT + NOTIFICATION_BANNER_HEIGHT + 1;
  const checkScrollDistance = useCallback((scrollDistance) => {
    if (scrollDistance > threshold && !showStickyHeader) setShowStickyHeader(true);
    if (scrollDistance < threshold && showStickyHeader) setShowStickyHeader(false);
  }, [showStickyHeader, threshold]);

  const handleScroll = useCallback(() => {
    const scrollDistance = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
    checkScrollDistance(scrollDistance);
  }, [checkScrollDistance]);

  useEffect(() => {
    window.document.addEventListener('scroll', handleScroll);
    return () => window.document.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const theme = useThemeContext();
  const smBreakPoint = +(theme.breakpoint.sm.min.split('px')[0]);
  const IPHONE_X_WIDTH = smBreakPoint - 201;

  return (
    <Styled.StickyHeaderStyled
      $showStickyHeader={showStickyHeader}
      $offset={threshold}
    >
      <Styled.ContainerStyled>
        <Styled.LogoStyled>
          <Styled.CobrandingLogoStyled />
        </Styled.LogoStyled>
        <ButtonLink
          kind="primary-sm"
          link="/oneflare-app?utm_campaign=header"
          target="_blank"
        >
          {viewportWidth >= IPHONE_X_WIDTH ? 'Open in app' : 'Open app'}
        </ButtonLink>
      </Styled.ContainerStyled>
    </Styled.StickyHeaderStyled>
  );
};

export default OpenInAppStickyHeader;
