import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import Button from '@oneflare/flarekit/lib/components/Button';
import H2 from '@oneflare/flarekit/lib/components/H2';
import H3 from '@oneflare/flarekit/lib/components/H3';
import Star from '@oneflare/flarekit/lib/components/icons/Star';
import media from '@oneflare/flarekit/lib/libs/media';
import Image from 'next/image';
import { styled, css } from 'styled-components';
import { type DefaultTheme } from 'styled-components';

const TransitionWrapperStyled = css`
  opacity: 0;
  transform: translateY(5vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 800ms ease-out, visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
  ${({ $isVisible }: {
    $isVisible: boolean;
    theme: DefaultTheme;
  }) => `
    ${$isVisible && `
    opacity: 1;
    transform: none;
    visibility: visible;`}
  `};
`;

export const PopCatContainerStyled = styled.section<{
  $isVisible: boolean;
}>`
  ${TransitionWrapperStyled}

  margin: 0 auto;
  padding: 0 20px;
  max-width: 1120px;

  ${media.mdUp`
    padding: 16px;
  `}

  ${media.lgUp`
    margin: 0 auto;
    padding: 0 40px;
  `}

  ${media.xlUp`
    margin: 0 auto;
    padding: 0;
  `}
`;


export const HeaderStyled = styled(H2)`
  margin: 80px 0 24px;
  line-height: 1.5;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};

  ${media.mdUp`
    margin: 128px 24px 24px;
  `}

  ${media.lgUp`
    margin: 160px 0 24px;
  `}
`;

export const OtherPopularCategoriesHeader = styled(H2)`
  margin: 0 0 16px;
  width: 100%;
  line-height: 1.5;
  font-size: 18px;
  font-family: ${({ theme }) => theme.font.secondaryBold};
  color: ${({ theme }) => theme.color.charcoal};

  ${media.lgUp`
  margin: 0 0 24px;
  `}
`;

export const PopularCategoriesWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;

  ${media.mdUp`
    padding: 0 12px;
  `}

  ${media.lgUp`
    padding: 0;
  `}

  ${media.xlUp`
    padding: 0 auto;
    width: 1150px;
  `}
`;

export const OtherPopularCategoriesWrapper = styled.section`
  margin: 0 0 24px;
  display: flex;
  flex-wrap: wrap;

  &.hide {
    display: none;
  }

  ${media.mdUp`
    margin: 0 24px 32px;
  `}

  ${media.lgUp`
    margin: 0 0 32px;
  `}
`;

export const CategoryTile = styled(Anchor)`
  margin: 0 0 22px;
  line-height: 1.5;

  &:hover {
    text-decoration: none;
  }

  display: flex;
  margin-right: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 45%;
  position: relative;
  flex-grow: 1;

  &:nth-child(2n){
    margin-right: 0;
  }

  ${media.mdUp`
    width: calc(100% / 3);
    margin-bottom: 52px;
    padding: 0 12px;
  `}

  ${media.lgUp`
    margin: 0 10px 40px;
    padding: 0;
    max-width: 23%;
    &:nth-child(2n){
      margin-right: 10px;
    }
    &:nth-child(4n){
      margin-right: 0px;
    }
  `}
`;

export const CategoryImageWrapper = styled.div`
  border-radius: 4px;
  display: flex;
  height: 126px;
  width: 100%;
  position: relative;
  overflow: hidden;
  object-fit: cover;

  ${media.lgUp`
    height: 180px;
  `}
`;

export const CategoryImage = styled(Image)`
  object-fit: cover
`;

export const CategoryHeader = styled(H3)`
  display: flex;
  margin: 8px 0 4px;
  line-height: 1.5;
  width: 100%;
  font-size: 16px;
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-weight: bold;
  color: ${({ theme }) => theme.color.charcoal};

  ${media.lgUp`
    margin: 8px 0 4px;
  `}

  &:hover {
    text-decoration: underline;
  }
`;

export const CategoryRating = styled.span`
  display: inline;
  color: ${({ theme }) => theme.color.gunMetal};
  font-size: 13px;
  font-weight: bold;
  position: relative;
  top: -2px;

  ${media.lgUp`
    top: 0;
  `}
`;

export const RatingIcon = styled(Star).attrs(({ theme }) => ({
  fillStar: theme.color.tertiaryDarker,
  kind: 'solid',
  width: '12px',
  height: '12px'
}))`
  position: relative;
  top: 1px;
  padding: 0 6px 0 2px;
`;

export const CategoryReviews = styled.span`
  margin-top: 2px;
  line-height: 1.5;
  color: ${({ theme }) => theme.color.spaceGrey};
  font-size: 13px;
  font-weight: normal;
  ${media.lgUp`
    margin-top: 0;
    display: inline;
  `}
`;

export const SeeMoreOrLessButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 0;
  font-weight: bold;

  ${media.mdUp`
    width: auto;
    margin-left: 24px;
    margin-top: 8px;
  `}

  ${media.lgUp`
    width: auto;
    margin-left: 0;
  `}
`;

export const OtherPopularCategoryLink = styled(Anchor)`
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 1.5;
  padding-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 45%;
  position: relative;
  flex-grow: 1;

  &:nth-child(2n){
    margin-right: 20px;
  }
  ${media.lgUp`
    max-width: 23%;
    margin-right: 20px;
    &:nth-child(4n+1){
      margin-right: 0;
    }
  `}
`;

export const TabMenuContainer = styled.section`
  display: flex;
  padding-bottom: 32px;
  overflow: scroll;

  ${media.mdUp`
    padding-left: 24px;
    padding-bottom: 40px;
  `}

  ${media.lgUp`
    overflow: initial;
    padding-left: 0;
  `}
`;

export const TabMenuAnchor = styled(Anchor)`
  font-family: ${({ theme }) => theme.font.primaryRegular};
  font-size: 16px;
  color: ${({ theme }) => theme.color.gunMetal};
  text-transform: capitalize;
  white-space: nowrap;
  margin: 0 23px 8px 0;

  &:hover {
    color: ${({ theme }) => theme.color.gunMetal};
    text-decoration: none !important;
    border-bottom: solid 2px ${({ theme }) => theme.color.spaceGrey};
  }

  ${({ $active }) => {
    if ($active) {
      return css`
      &.active {
        border-bottom: solid 2px ${({ theme }) => theme.color.primary};
        font-weight: bold;
        color: ${({ theme }) => theme.color.charcoal};
      }
    `;
    }
    return null;
  }}
`;
