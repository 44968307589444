import PropTypes from 'prop-types';

import {
  QuoteButtonStyled,
  SearchStyled,
  AutoCompleteStyled,
  SearchButtonStyled
} from '../styled/HeaderStickyStyled';

const PostJobAutoCompleteCta = ({
  onClick,
  value,
  onSelect,
  autocompleteTitle,
  onChange,
  items,
  useGetFreeQuoteText
}) => {
  return (
    <>
      <QuoteButtonStyled
        onClick={onClick}
        label={useGetFreeQuoteText ? 'Get free quotes' : 'Post a job'}
        kind="default-sm"
      />
      <SearchStyled>
        <AutoCompleteStyled
          type="text"
          label={autocompleteTitle}
          id="location-header"
          items={items}
          onChange={onChange}
          onSelect={onSelect}
          placeholder="e.g. Sydney, 2000"
          value={value}
        />
        <SearchButtonStyled onClick={onClick} label="GO" kind="primary-sm" />
      </SearchStyled>
    </>
  );
};

PostJobAutoCompleteCta.propTypes = {
  autocompleteTitle: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string
  })),
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onSelect: PropTypes.func,
  useGetFreeQuoteText: PropTypes.bool.isRequired,
  value: PropTypes.string
};

PostJobAutoCompleteCta.defaultProps = {
  onClick: () => {},
  onChange: () => {},
  onSelect: () => {},
  items: {
    name: '',
    value: ''
  },
  autocompleteTitle: '',
  value: ''
};

export default PostJobAutoCompleteCta;
