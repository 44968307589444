import Cobranding from '@oneflare/flarekit/lib/components/logos/Cobranding';
import Tracker from '@oneflare/flarekit/lib/libs/Tracker/Tracker';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import {
  memo,
  useCallback,
  useMemo,
  useEffect,
  useState,
  useContext,
  forwardRef
} from 'react';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import { useCurrentUser } from 'shared/utils/getCurrentUser';
import { indefiniteArticleCheck } from 'shared/utils/helpers';
import { useBreakpoint } from 'shared/utils/hooks';
import type { ICurrentUser } from 'types/oneflare.com.au/user';
import type { CategoryModel } from 'types/shared';

import HeaderSticky from '../HeaderSticky/HeaderSticky';

import { CTA_TYPES } from './Assets';
import OpenInAppStickyHeader from './components/OpenInAppStickyHeader';
import { HeaderVariants } from './HeaderVariants';
import { HeaderLoading } from './styled/PageHeader';

export enum HeaderKind {
  DEFAULT = 'default',
  OVERLAY = 'overlay',
  BORDERLESS = 'bordless',
  OVERLAY_DARK = 'overlayDark',
  OVERLAY_LIGHT = 'overlayLight'
}

export interface IPageHeader {
  category?: Pick<CategoryModel, 'slug' | 'singular' | 'plural' | 'id' | 'noun' | 'verb'>;
  categoryId?: number | string;
  className?: string;
  directLeadsNumber?: string;
  ctaKind?: (typeof CTA_TYPES)[keyof typeof CTA_TYPES];
  directLeadsTracking?: () => void;
  fetchPolicy?: string;
  handleCallClick?: () => void;
  hideGuestCta?: boolean;
  hideStickyHeader?: boolean;
  autoCompleteTitle?: string;
  isBot?: boolean;
  job_form_question?: string;
  kind?: HeaderKind;
  loading?: boolean;
  mobileCta?: {
    link: string;
    label: string;
  };
  openInAppStickyHeader?: boolean;
  postcode?: string;
  zoneId?: number;
}

const PageHeader = forwardRef<
  HTMLDivElement,
  IPageHeader
>(({
  className = '',
  category,
  categoryId,
  job_form_question = '',
  ctaKind = CTA_TYPES.POST_JOB,
  directLeadsNumber,
  zoneId,
  hideStickyHeader = false,
  postcode = '',
  kind = HeaderKind.DEFAULT,
  isBot = false,
  openInAppStickyHeader = false,
  mobileCta,
  hideGuestCta = false
}, ref) => {
  const isMobile = !useBreakpoint('lg');
  const { controller } = useContext(JobFormControllerContext);
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const { DOMAIN } = publicRuntimeConfig;
  const isOneflare = DOMAIN === 'oneflare';
  const router = useRouter();
  const query = router?.query;
  const jobFormHost = (query?.jobFormHost || publicRuntimeConfig.JOB_FORM_URL) as string;
  const [currentUser, setCurrentUser] = useState<ICurrentUser>({} as ICurrentUser);
  const [showOpenInAppStickyHeader, setShowOpenInAppStickyHeader] = useState(false);
  const [showStickyWebHeader, setShowStickyWebHeader] = useState(false);
  const { loading: currentUserLoading } = useCurrentUser({
    onCompleted: (data) => {
      if (data.currentUser) {
        const authenticatedUser = data.currentUser.user;
        const currentUserCopy = cloneDeep(data.currentUser);
        const validUserCopy = !isEmpty(currentUserCopy);
        const userExists = !isEmpty(authenticatedUser);
        const noBusinessUser = userExists && !authenticatedUser.isBusiness || !userExists;
        if (validUserCopy && authenticatedUser) {
          currentUserCopy.hostname = process.env.SITE_URL as string || '';
          if (authenticatedUser.roles) {
            currentUserCopy.user.isAdmin = authenticatedUser.roles.includes('super_admin');
          }
          if (authenticatedUser.avatar && authenticatedUser.avatar.url) {
            currentUserCopy.user.avatarUrl = authenticatedUser.avatar.url;
          }
        }
        setCurrentUser(currentUserCopy);
        const showOpenInAppStickyHeader = openInAppStickyHeader && noBusinessUser && isMobile;
        setShowOpenInAppStickyHeader(showOpenInAppStickyHeader);
        const showStickWebHeader = !hideStickyHeader && noBusinessUser && !showOpenInAppStickyHeader;
        setShowStickyWebHeader(showStickWebHeader);
      }
    }
  });

  const autoCompleteTitle = useMemo(() => {
    if (ctaKind === CTA_TYPES.POST_JOB_AUTOCOMPLETE && !isEmpty(category)) {
      return `Where do you need ${String(indefiniteArticleCheck(category?.noun)).toLowerCase()}?`;
    }
    return job_form_question;
  }, [ctaKind, category, job_form_question]);

  useEffect(() => {
    if (isEmpty(currentUser)) return;
    const { user } = currentUser;
    DataDogRumAgent.setRumUser(user);
  }, [
    currentUser
  ]);

  const checkScrollDistance = useCallback(
    (scrollDistance) => {
      if (scrollDistance > 73 && !showStickyHeader) {
        setShowStickyHeader(true);
      }
      if (scrollDistance < 73 && showStickyHeader) {
        setShowStickyHeader(false);
      }
    },
    [showStickyHeader]
  );

  const handleScroll = useCallback(() => {
    let timeout = undefined;
    const scrollDistance = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      checkScrollDistance(scrollDistance);
      clearTimeout(timeout);
    }, 10);
  }, [checkScrollDistance]);

  const handleCallClick = () => {
    document.location.href = `tel:${directLeadsNumber}`;
    Tracker.dataLayer.push({
      action: 'call_text',
      category: 'phone_number',
      category_id: categoryId ? String(categoryId) : null,
      category_name: category.slug,
      event: 'interaction',
      call_id: directLeadsNumber,
      label: 'phone_dsp'
    });
  };

  useEffect(() => {
    window.document.addEventListener('scroll', handleScroll);
    return () => window.document.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  if (isOneflare && isEmpty(currentUser.user) && currentUserLoading) {
    return (
      <HeaderLoading $isOverlay={kind === HeaderKind.OVERLAY}>
        <Cobranding />
      </HeaderLoading>
    );
  }

  return (
    <section className={className} ref={ref}>
      <HeaderVariants
        DOMAIN={DOMAIN}
        category={category}
        categoryId={categoryId}
        currentUser={currentUser}
        hideGuestCta={hideGuestCta}
        jobFormHost={jobFormHost}
        isOneflareClient={{
          openClientJobForm: async () => {
            await controller.open();
          }
        }}
        kind={kind}
        mobileCta={mobileCta}
        zoneId={zoneId}
      />
      {showOpenInAppStickyHeader && <OpenInAppStickyHeader />}
      {showStickyWebHeader && (
        <HeaderSticky
          handleCallClick={handleCallClick}
          directLeadsNumber={directLeadsNumber}
          ctaKind={ctaKind}
          category={category}
          postcode={postcode}
          useGetFreeQuoteText={!isBot}
          showStickyHeader={showStickyHeader}
          autocompleteTitle={autoCompleteTitle}
        />
      )}
    </section>
  );
});

PageHeader.displayName = 'PageHeader';

export default memo(PageHeader);
