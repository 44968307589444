import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  split
} from '@apollo/client';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';

import { publicRuntimeConfig } from 'lib/utils/Environment';

import {
  authLink,
  csrfTokenLink,
  notificationLink,
  errorHandlerLink,
  restLink,
  link
} from './links';
import { typePolicies } from './typePolicies';

const hasSubscriptionOperation = ({ query: { definitions } }) => {
  return definitions.some(
    ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription'
  );
};

const splitLink = () => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line global-require
    const ActionCable = require('actioncable');
    const cable = ActionCable.createConsumer(publicRuntimeConfig.GRAPHQL_API_WS);
    return split(
      hasSubscriptionOperation,
      new ActionCableLink({ cable }),
      link
    );
  }
  return link;
};

export const createApolloClient = ({ ctx, initialState = null }) => {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined', // prevent apollo from fetching queries unnecessarily
    link: ApolloLink.from([
      restLink,
      authLink(ctx),
      csrfTokenLink,
      notificationLink,
      errorHandlerLink,
      splitLink()
    ]),
    connectToDevTools: true,
    defaultOptions: {
      query: {
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    },
    cache: new InMemoryCache({
      typePolicies
    }).restore(initialState),
    credentials: 'include'
  });
};

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */

let globalApolloClient = null;

export default function initApolloClient(ctx, initialState) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient({ ctx, initialState });
  }
  // Reuse client on the client-side
  if (!globalApolloClient) {
    globalApolloClient = createApolloClient({ ctx, initialState });
  }
  return globalApolloClient;
}
