import Header from '@oneflare/flarekit/lib/components/Header';
import Cobranding from '@oneflare/flarekit/lib/components/logos/Cobranding';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const PageHeaderStyled = styled(Header)`
  position: ${({ kind }) => (kind === 'overlay' ? 'absolute' : 'relative')};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex[2]};
`;

export const HeaderLoading = styled.div<{
  $isOverlay?: boolean;
}>`
  box-sizing: content-box;
  height: 72px;
  position: ${({ $isOverlay }) => ($isOverlay ? 'absolute' : 'unset')};
  border-bottom: ${({ theme, $isOverlay }) => (`1px solid ${$isOverlay ? 'transparent' : theme.color.cloud}`)};
  align-items: center;
  z-index: 1;
  display: flex;
  padding-left: 20px;
  opacity: 1;

  ${media.mdUp`
    padding-left: 40px;
  `}
`;

export const CobrandingLogoStyled = styled(Cobranding)`
  height: 22px;
  width: 117px;

  ${media.mdUp`
    height: 28px;
    width: 150px;
  `}
`;
