import {
  trackAboutUsPageView,
  trackBusinessDirectoryView,
  trackContactUsPageView,
  trackCostGuidesView,
  trackHomepageView,
  trackOneflareBusinessAwardsPageView,
  trackWinWorkGuideView,
  trackInspirationPageView,
  trackBusinessProfileView,
  trackBusinessCentreView,
  type TrackBusinessProfileViewOptions,
  type TrackCostGuidesViewOptions,
  type TrackInspirationPageViewOptions,
  trackBusinessDirectoryCategoryPageView,
  type TrackBusinessDirectoryCategoryPageViewOptions,
  trackBusinessDirectoryLocationPageView,
  type TrackBusinessDirectoryLocationPageViewOptions,
  trackReviewsPageView
} from '@oneflare/web-analytics';
import { Dispatch, SetStateAction } from 'react';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { useCurrentUser } from 'shared/utils/getCurrentUser';
import { type User } from 'types/oneflare.com.au/user';

import { OneflareAnalytics } from './oneflareAnalytics';

enum PageViewType {
  Contact = 'contact',
  Awards = 'awards',
  Home = 'home',
  WinwWorkGuide = 'win-work-guide',
  About = 'about',
  CostGuides = 'cost-guides',
  Directory = 'directory',
  Inspiration = 'inspiration',
  BusinessProfile = 'business-profile',
  BusinessDirectoryCategory = 'business-directory-category',
  BusinessDirectoryLocation = 'business-directory-location',
  Reviews = 'reviews',
  BusinessCentre = 'business-centre'
}

const trackingMethods: {
  [key in PageViewType]: (tracker: OneflareAnalytics, source: string, opts?: Options) => void;
} = {
  [PageViewType.Contact]: trackContactUsPageView,
  [PageViewType.Awards]: trackOneflareBusinessAwardsPageView,
  [PageViewType.WinwWorkGuide]: trackWinWorkGuideView,
  [PageViewType.Home]: trackHomepageView,
  [PageViewType.About]: trackAboutUsPageView,
  [PageViewType.CostGuides]: trackCostGuidesView,
  [PageViewType.Directory]: trackBusinessDirectoryView,
  [PageViewType.Inspiration]: trackInspirationPageView,
  [PageViewType.BusinessProfile]: trackBusinessProfileView,
  [PageViewType.BusinessDirectoryCategory]: trackBusinessDirectoryCategoryPageView,
  [PageViewType.BusinessDirectoryLocation]: trackBusinessDirectoryLocationPageView,
  [PageViewType.Reviews]: trackReviewsPageView,
  [PageViewType.BusinessCentre]: trackBusinessCentreView
};

type Options =
  | TrackCostGuidesViewOptions
  | TrackInspirationPageViewOptions
  | TrackBusinessProfileViewOptions
  | TrackBusinessDirectoryCategoryPageViewOptions
  | TrackBusinessDirectoryLocationPageViewOptions;

const useTrackPageView = (
  method: keyof typeof trackingMethods,
  setUser?: Dispatch<SetStateAction<User>>,
  opts?: Options
) => {
  const CODE_SOURCE = 'useTrackPageView:useCurrentUser';
  const trackPageView = (tracker: OneflareAnalytics) => {
    if (opts) {
      trackingMethods[method](tracker, CODE_SOURCE, opts);
    } else {
      trackingMethods[method](tracker, CODE_SOURCE);
    }
  };

  useCurrentUser({
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      let tracker: OneflareAnalytics;
      if (data && data.currentUser) {
        const { currentUser: { user } } = data;
        if (setUser) {
          setUser(user);
        }
        tracker = new OneflareAnalytics(String(user.id));
      } else {
        tracker = new OneflareAnalytics();
      }
      trackPageView(tracker);
    },
    onError: (error) => {
      const tracker = new OneflareAnalytics();
      DataDogRumAgent.addRumError(
        error,
        `Oneflare | useTrackPageView | useCurrentUser | ${method}`
      );
      trackPageView(tracker);
    }
  });
};

export { useTrackPageView, PageViewType };
