import { ErrorResponse, onError } from '@apollo/client/link/error';

import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';

export const errorHandlerLink = onError((error: ErrorResponse) => {
  const { graphQLErrors, networkError, operation } = error;
  const { rumErrorMessage } = operation.getContext();
 
  const errorMessage = typeof rumErrorMessage === 'function'
        ? rumErrorMessage({ graphQLErrors, networkError }, operation)
        : rumErrorMessage;
  
  if (errorMessage) {
    DataDogRumAgent.addRumError(error, errorMessage);
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { message, locations, path, extensions } = error;
      
      if (typeof window !== 'undefined') {
        DataDogRumAgent.addRumError(
          error,
          `Oneflare | Operation: ${operation.operationName} | Message: ${message} | Location: ${locations} | Path: ${path}`,
          extensions
        );
      } else {
        // restrict console errors to server side
        console.error(errorMessage);
      }
    });
  }

  if (networkError) {
    console.warn(`[Network error]: ${networkError}`);
    
    if (typeof window !== 'undefined') {
      DataDogRumAgent.addRumError(
        networkError,
        `Oneflare | Operation: ${operation.operationName} | Network Error`
      );
    } 
  }
});
